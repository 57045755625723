
import React, { memo, useEffect } from "react"
import smoothscroll from 'smoothscroll-polyfill';
import "../styles/site.scss"

const Layout = ({ children }) => {

  useEffect(() => {
    smoothscroll.polyfill();
  }, [])

  return (
    <>
      {children}
    </>
  )
}


export default memo(Layout)
